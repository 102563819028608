import '@beyonk/gdpr-cookie-consent-banner/dist/browser/bundle'
import SmoothScroll from "smooth-scroll"
import { animate } from 'popmotion'

import "./components/sliders/banner-slider"
import "./components/sliders/review-slider"
import "./components/sliders/image-slider"
import "./components/extension-blocks"
import "./layout/header"
import "./components/location-map"
import "./components/review-slider-stars"
import "./components/review-total-stars"
import "./components/occasion-detail"
import "./components/marked-map"
import "./components/highlighted-occasions"


window.GdprConsent.attachBanner(document.body, {
    /**
     * You must set the cookie name.
     **/
    cookieName: 'carxpert_gdpr_v2',
  
    /**
     * The cookie configuration, such as domain and path.
     **/
    // cookieConfig: {
    //   domain: 'allparts.nl',
    //   path: '/'
    // },
  
    /**
     * These are the top two lines of text on the banner
     * The 'description' field can include html such as links
     **/
    heading: 'Deze website maakt gebruik van cookies',
    description: 'We gebruiken cookies om content en advertenties te personaliseren, om functies voor social media te bieden en om ons websiteverkeer te analyseren. Ook delen we informatie over uw gebruik van onze site met onze partners voor social media, adverteren en analyse. Deze partners kunnen deze gegevens combineren met andere informatie die u aan ze heeft verstrekt of die ze hebben verzameld op basis van uw gebruik van hun services.',
  
    /**
     * All the button labels
     **/
    acceptLabel: 'Alle cookies toestaan',
    settingsLabel: 'Voorkeuren',
    closeLabel: 'Sluiten',
  
    /**
     * These are the default opt-ins and their descriptions.
     * When value is set to true, the option will automatically be checked on load.
     *
     * If you don't want to show a category, simply remove the specified key from this object.
     **/
    choices: {
      necessary: {
        label: 'Noodzakelijk',
        description:
          "Verplicht - kan niet worden gedeselecteerd. Noodzakelijke cookies helpen een website bruikbaarder te maken, door basisfuncties als paginanavigatie en toegang tot beveiligde gedeelten van de website mogelijk te maken. Zonder deze cookies kan de website niet naar behoren werken.",
        value: true
      },
      analytics: {
        label: 'Statistieken',
        description:
          "Statistische cookies helpen eigenaren van websites begrijpen hoe bezoekers hun website gebruiken, door anoniem gegevens te verzamelen en te rapporteren.",
        value: true
      },
      marketing: false,
      tracking: false
    },
  
    /**
     * Show an icon to edit cookies later, when banner is closed.
     **/
    showEditIcon: true,
  
    /**
     * These are the functions which are run if a user opts-in to that category.
     * You should drop your cookies here (or set a variable to control the later dropping of cookies.
     *
     * If you are using svelte, you can use events instead - see the Svelte section below.
     **/
    categories: {
      analytics: function() {
       
      },
      tracking: function() {
        // console.log('No tracking cookies specified')
      },
      marketing: function() {
        // console.log('No marketing cookies specified')
      },
      necessary: function() {
        // console.log('No necessary cookies specified')
      }
    }
  })

new SmoothScroll('a[href^="#"]', {
    speed: 300,
    offset: function () {
        return 100
    },
    easing: "Linear",
    ignore: 'a[href^="#openChat"]',
    topOnEmptyHash: false,
})


// document.addEventListener("DOMContentLoaded", function () {
//   const btn = document.querySelector(".action-popup button")
//   if (!btn) {
//     return;
//   }
  
//   btn.addEventListener("click", function () {
//     localStorage.setItem("popup-closed-2023", true)
//     document.querySelector(".action-popup").style.display = "none";
//   })


//   if (!localStorage.getItem("popup-closed-2023")) {
//     animate({
//       from: -200,
//       to: 15,
//       autoplay: true,
//       onUpdate: (v) => {
//         document.querySelector(".action-popup").style.right = `${v}px`
//       },
//       onPlay: () => {
//         document.querySelector(".action-popup").style.display = "flex";
//       }
//     })
//   }
// });